/************
 * Developed by : Shiva Software Solutions
 * Date : 11-05-2023
 * Descriptions : Reports page api calling
 ************/
import React from 'react';
import Api from '../../@crema/services/ApiConfig';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
// const variables
import {
  sendReview,
  backReview,
  userRoleMaster,
} from '../../shared/constants/AppConst';
import {DataBrew} from 'aws-sdk';
import {
  FETCH_START,
  FETCH_ERROR,
  OPDDIGITIZATION_COUNT,
  OPDDIGITIZATION_MEDICINE,
  DIGITIZATION_DISEASE,
  INVESTIGATION_LAB,
  INVESTIGATION_RADIO,
  FETCH_SUCCESS,
  MEDICINE_CATEGORY_COUNT,
  DOCTOR_SPECIALIZATION_COUNT,
  INVESTIGATION_MRI_XRay,
  INVESTIGATION_BLOOD_TEST,
} from '../../shared/constants/ActionTypes';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'reports.js';

export const mastereRx = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/ongetclientlistjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/ongetclientlist', value)
              .then((data) => {})
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'ongetclientlist',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'ongetclientlist',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetclientlist',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'ongetclientlist', '0', err),
      );
    }
  };
};

export const OPDDigitization_chart = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/OPDDigitization_chartJWT', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/OPDDigitization_chart', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: OPDDIGITIZATION_COUNT,
                  payload:
                    data.data.body.reportData?.length > 0
                      ? data.data.body.reportData[0]
                      : {},
                });
                //medicine chart
                dispatch({
                  type: OPDDIGITIZATION_MEDICINE,
                  payload: data.data.body.medicineReport,
                });
                //disease chart
                dispatch({
                  type: DIGITIZATION_DISEASE,
                  payload: data.data.body.diseaseReport,
                });
                //investigation lab
                dispatch({
                  type: INVESTIGATION_LAB,
                  payload: data.data.body.labInvesReport,
                });
                //investigation Radiology
                dispatch({
                  type: INVESTIGATION_RADIO,
                  payload: data.data.body.radioInvesReport,
                });

                //doctor specialization
                dispatch({
                  type: DOCTOR_SPECIALIZATION_COUNT,
                  payload: data.data.body.doctorSpecialization,
                });
                //medcategory based
                // dispatch({
                //   type: MEDICINE_CATEGORY_COUNT,
                //   payload: data.data.body.medCategoryReport,
                // });

                //MRI / X-Ray based
                dispatch({
                  type: INVESTIGATION_MRI_XRay,
                  payload: data.data.body.mri_xRayReport,
                });

                dispatch({
                  type: INVESTIGATION_BLOOD_TEST,
                  payload: data.data.body.bloodTestReport,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'OPDDigitization_chart',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'OPDDigitization_chartJWT',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'OPDDigitization_chartJWT',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'OPDDigitization_chart',
          '0',
          err,
        ),
      );
    }
  };
};
