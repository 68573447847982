/************
 * Author : Sakthipriya
 * Developed by : Shiva Software Solutions
 * Date : 11-05-2023
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  CLIENT_LIST_REPORT,
  DOCTOR_REPORT,
  SEARCH_DISEASE_DATA,
  REPORT_RESULT,
  REGI_REPORT_RESULT,
  REPORT_OLD_RESULT,
  REPORT_RESULT_SUCCESS,
  REPORT_OLD_RESULT_SUCCESS,
  DIGI_REPORT_RESULT,
  DIGI_PREVIEW_DATA,
  DIGI_REPORT_RESULT_SUCCESS,
  REPORT_COUNT,
  REPORT_OLD_COUNT,
  DIGI_REPORT_COUNT,
  EXCEL_REPORT_LIST,
  EXCEL_OLD_REPORT_LIST,
  EXCEL_AMAZON_REPORT_LIST,
  DIGI_AMAZON_REPORT_LIST,
  PROCEDURE_REPORT,
  ALLERGY_REPORT,
  TAG_CATEGORY_REPORT,
  SEARCH_DIAGONSIS_DATA,
  ERX_DATA_RHTML_NO_DATA,
  SEARCH_SPECIALIZATION_DATA,
  SEARCH_SYMPTOMS_DATA,
  SEARCH_MEDICINE_DATA,
  ERX_DATA_RHTML,
  OPDDIGITIZATION_COUNT,
  OPDDIGITIZATION_MEDICINE,
  DIGITIZATION_DISEASE,
  INVESTIGATION_LAB,
  INVESTIGATION_RADIO,
  MEDICINE_CATEGORY_COUNT,
  DOCTOR_SPECIALIZATION_COUNT,
  INVESTIGATION_MRI_XRay,
  INVESTIGATION_BLOOD_TEST,
} from '../../shared/constants/ActionTypes';
const initialState = {
  clientlist: '',
  doctorlist: '',
  reportcount: '',
  reportoldcount: '',
  digireportcount: '',
  allergyreport: '',
  procedurereport: '',
  search_disease_data: [],
  excel_report: [],
  excel_old_report: [],
  excel_amazon_report: [],
  excel_digi_report: [],
  tagcategory: [],
  reportresult: [],
  rigireportresult: [],
  reportOldresult: [],
  digireportresult: [],
  digiPreviewdata: [],
  search_diagonsis_data: [],
  search_symptoms_data: [],
  search_medicine_data: [],
  search_specialization_data: [],
  reportresultsuccess: '',
  reportoldresultsuccess: '',
  digireportresultsuccess: '',
  erxrhtmldata: '',
  erxrhtmlnodata: '',
  opddigitization_report_count: {},
  opddigitization_report_medicine: [],
  digitization_report_diseases: [],
  inves_labtype: [],
  inves_radiotype: [],
  medicine_category_count: [],
  inves_MRI_XRay: [],
  doctor_specialization: [],
  inves_blood_test: [],
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_LIST_REPORT:
      return {
        ...state,
        clientlist: action.payload,
      };
    case REPORT_RESULT:
      return {
        ...state,
        reportresult: action.payload,
      };
    case REGI_REPORT_RESULT:
      return {
        ...state,
        rigireportresult: action.payload,
      };
    case REPORT_OLD_RESULT:
      return {
        ...state,
        reportOldresult: action.payload,
      };
    case REPORT_RESULT_SUCCESS:
      return {
        ...state,
        reportresultsuccess: action.payload,
      };
    case REPORT_OLD_RESULT_SUCCESS:
      return {
        ...state,
        reportoldresultsuccess: action.payload,
      };
    case DIGI_REPORT_RESULT:
      return {
        ...state,
        digireportresult: action.payload,
      };
    case DIGI_PREVIEW_DATA:
      return {
        ...state,
        digiPreviewdata: action.payload,
      };
    case DIGI_REPORT_RESULT_SUCCESS:
      return {
        ...state,
        digireportresultsuccess: action.payload,
      };

    case REPORT_COUNT:
      return {
        ...state,
        reportcount: action.payload,
      };
    case REPORT_OLD_COUNT:
      return {
        ...state,
        reportoldcount: action.payload,
      };
    case DIGI_REPORT_COUNT:
      return {
        ...state,
        digireportcount: action.payload,
      };
    case EXCEL_REPORT_LIST:
      return {
        ...state,
        excel_report: action.payload,
      };
    case EXCEL_OLD_REPORT_LIST:
      return {
        ...state,
        excel_old_report: action.payload,
      };
    case EXCEL_AMAZON_REPORT_LIST:
      return {
        ...state,
        excel_amazon_report: action.payload,
      };
    case DIGI_AMAZON_REPORT_LIST:
      return {
        ...state,
        excel_digi_report: action.payload,
      };
    case ALLERGY_REPORT:
      return {
        ...state,
        allergyreport: action.payload,
      };
    case PROCEDURE_REPORT:
      return {
        ...state,
        procedurereport: action.payload,
      };
    case SEARCH_MEDICINE_DATA:
      return {
        ...state,
        search_medicine_data: action.payload,
      };
    case TAG_CATEGORY_REPORT:
      return {
        ...state,
        tagcategory: action.payload,
      };
    case DOCTOR_REPORT:
      return {
        ...state,
        doctorlist: action.payload,
      };
    case SEARCH_DISEASE_DATA:
      return {
        ...state,
        search_disease_data: action.payload,
      };
    case SEARCH_DIAGONSIS_DATA:
      return {
        ...state,
        search_diagonsis_data: action.payload,
      };
    case ERX_DATA_RHTML:
      return {
        ...state,
        erxrhtmldata: action.payload,
      };
    case ERX_DATA_RHTML_NO_DATA:
      return {
        ...state,
        erxrhtmlnodata: action.payload,
      };
    case SEARCH_SYMPTOMS_DATA:
      return {
        ...state,
        search_symptoms_data: action.payload,
      };
    case SEARCH_SPECIALIZATION_DATA:
      return {
        ...state,
        search_specialization_data: action.payload,
      };
    case OPDDIGITIZATION_COUNT:
      return {
        ...state,
        opddigitization_report_count: action.payload,
      };
    case OPDDIGITIZATION_MEDICINE:
      return {
        ...state,
        opddigitization_report_medicine: action.payload,
      };
    case DOCTOR_SPECIALIZATION_COUNT:
      return {
        ...state,
        doctor_specialization: action.payload,
      };
    case DIGITIZATION_DISEASE:
      return {
        ...state,
        digitization_report_diseases: action.payload,
      };
    case INVESTIGATION_LAB:
      return {
        ...state,
        inves_labtype: action.payload,
      };
    case INVESTIGATION_RADIO:
      return {
        ...state,
        inves_radiotype: action.payload,
      };
    case MEDICINE_CATEGORY_COUNT:
      return {
        ...state,
        medicine_category_count: action.payload,
      };

    case INVESTIGATION_MRI_XRay:
      return {
        ...state,
        inves_MRI_XRay: action.payload,
      };
    case INVESTIGATION_BLOOD_TEST:
      return {
        ...state,
        inves_blood_test: action.payload,
      };
    default:
      return state;
  }
};
export default reportReducer;
