/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : GudMed UserList
 * Dependicies : To use @material-ui/core
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const masterConfigs = [
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/symptoms',
        component: React.lazy(() => import('./symptoms')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/diagnosis',
        component: React.lazy(() => import('./diagnosis')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/procedure',
        component: React.lazy(() => import('./procedure')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/investigation',
        component: React.lazy(() => import('./investigation')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/allergy',
        component: React.lazy(() => import('./allergy')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/diseases',
        component: React.lazy(() => import('./diseases')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/clients',
        component: React.lazy(() => import('./clients')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/cameramaster',
        component: React.lazy(() => import('./camera')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/edit-client',
        component: React.lazy(() => import('./clients/editClients/SimpleTabs')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/specialization',
        component: React.lazy(() => import('./departments')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/languageMaster',
        component: React.lazy(() => import('./languageMaster')),
      },
    ],
  },
];
