import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect} from 'react';
import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import {ContentView} from '../../index';
import useStyles from '../../../shared/jss/common/common.style';
// import {
//   onCognitoUserSignOut,
//   onJWTAuthSignout,
//   onSignOutAuth0User,
//   onSignOutFirebaseUser,
// } from '../../../redux/actions';
// import {useDispatch} from 'react-redux';
// import {useAuthUser} from '../../utility/AppHooks';
// import {AuthType} from '../../../shared/constants/AppEnums';
// import {Prompt} from 'react-router';
const useStyle = makeStyles(() => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      scrollBehavior: 'smooth', // Smooth scroll behavior
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {},
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

const CremaLayout = () => {
  // for ui styles
  useStyles();
  // useEffect(() => {
  //   const handleTabClose = event => {
  //     event.preventDefault();

  //     console.log('beforeunload event triggered');

  //     return (event.returnValue =
  //       'Are you sure you want to exit?');
  //   };

  //   window.addEventListener('onbeforeunload ', handleTabClose);

  //   return () => {
  //     window.removeEventListener('onbeforeunload ', handleTabClose);
  //   };
  // }, []);
  const {navStyle} = useContext(AppContext);
  // const dispatch = useDispatch();
  // get user detail from redux
  const {user} = useSelector(({auth}) => auth);
  // const userval = useAuthUser();
  // get layout styles
  const AppLayout = Layouts[navStyle];
  // declare the styles
  const classes = useStyle();
  //  var beforeUnloadTimeout = 0;
  // useEffect(() => {
  //   window.addEventListener('beforeunload', function (e) {
  //     let confirmationMessage = 'o/';
  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //     beforeUnloadTimeout = setTimeout(function () {}, 500);
  //     return 'are you sure';
  //   });
  //   window.addEventListener('unload', function (e) {
  //     if (
  //       typeof beforeUnloadTimeout !== 'undefined' &&
  //       beforeUnloadTimeout != 0
  //     )
  //       clearTimeout(beforeUnloadTimeout);
  //   });
  // });
  //    //Logout process
  //    window.localStorage.clear();
  //    if (userval && userval.authType === AuthType.AWS_COGNITO) {
  //      dispatch(onCognitoUserSignOut());
  //    } else if (userval && userval.authType === AuthType.FIREBASE) {
  //      dispatch(onSignOutFirebaseUser());
  //    } else if (userval && userval.authType === AuthType.AUTH0) {
  //      dispatch(onSignOutAuth0User());
  //    } else if (userval && userval.authType === AuthType.JWT_AUTH) {
  //      // signout redux api calling
  //      let pushdata = {
  //        userId: user.userId,
  //        userCode: user.userCode,
  //        onlineStatus: 'Logout',
  //      };
  //      dispatch(onJWTAuthSignout(pushdata));
  //    }

  return (
    <>
      {user ? (
        <AppLayout />
      ) : (
        <Box className={classes.appAuth}>
          <ContentView />
        </Box>
      )}
    </>
  );
};

export default React.memo(CremaLayout);
