/************
 * Developed by : Shiva Software Solutions
 * Date : 29-12-2023
 * Descriptions : Digitization reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  DIGI_PRESCRIPTION_DATA,
  DIGI_L1TAG,
  DIGI_PREVIEWIMG_DATA,
  DIGI_IMGVALID_DATA,
  DIGI_TAB_DATA,
  DIGI_INPUT_DATA,
  DIGI_FINAL_SUCCESS,
  DIGI_MEDICINE_DATA,
  DIGI_SEARCHMEDICINE_DATA,
  DIGI_GENERAL_SETTINGS,
  DIGI_SETTINGS_UPDATE,
  DIGI_NEWMEDICINE_DATA,
  DIGI_MEDINSERT_SUCCESS,
  DIGI_PRESCRIPTION_IMAGES,
  DIGI_INPROGRESS_PRESCRIPTION,
  DIGI_REF_MESSAGE_DATA,
  SEGREGATION_SUCCESS,
  VERIFICATION_SUCCESS,
  PRESCRIPTIONINFO_SUCCESS,
  REVERTSEGREGATION_SUCCESS,
  DIGI_TOASTER,
  DIGI_MED_SUGG,
  DIGI_SUCCESS_MSG,
  DIGI_PRESCRIPTIONNUMBER,
  DIGI_MEDCATEGORY,
  DIGI_UOMLIST,
  DIGI_DOSAGE_OPTIONS,
  DIGI_PRES_STATUS,
  DIGI_WARNING,
  DIGI_CURRENT_PRES,
  PRESCRIPTION_OCR_DATA,
  ISSUE_DATE,
  DIGITIZATION_DOCTORS_SUGGESTIONS,
  DIGITIZATION_DOCTOR_AUTOFILL,
  GET_NER_HOSPITALS,
  GET_DIGITIZATION_NER_INDEX,
  VITALS_SETTINGS_UPDATE,
  DIGI_AUTO_FILL_NOTIFIY,
} from '../../shared/constants/ActionTypes';

const initialState = {
  digiPresImagedata: '',
  prescriptionData: [],
  digitizationL1Tag: '',
  imgValidationData: [],
  prescriptionStatus: {},
  digiDosageOptions: [],
  tabData: {},
  digiAsinSugg: [],
  digiPresNumber: '',
  digitizationStatus: '',
  inputData: [],
  ocrData: [],
  issueDate: '',
  medicineData: [],
  searchmedicineData: [],
  generalSettings: '',
  settingsUpdate: '',
  searchnewmedicineData: [],
  inprogressPrescription: {},
  prescriptionImages: [],
  digitRefMessageID: {},
  segregationSuccess: '',
  digitiSuccessMsg: '',
  toaserMsg: '',
  warningMsg: '',
  presInfoSuccess: '',
  digitization_success: '',
  medCategoryList: [],
  uomList: [],
  verificationSuccess: '',
  nerdigiIndex: '',
  medicineInsertMsg: '',
  currentPrescription: 0,
  digi_autofill_notify: '',
};
//Get patient master reducer
const digitizationReducer = (state = initialState, action) => {
  switch (action.type) {
    //get patient data
    case DIGI_MEDCATEGORY:
      return {
        ...state,
        medCategoryList: action.payload,
      };
    case PRESCRIPTION_OCR_DATA:
      return {
        ...state,
        ocrData: action.payload,
      };
    case ISSUE_DATE:
      return {
        ...state,
        issueDate: action.payload,
      };
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationSuccess: action.payload,
      };
    case PRESCRIPTIONINFO_SUCCESS:
      return {
        ...state,
        presInfoSuccess: action.payload,
      };
    case DIGI_UOMLIST:
      return {
        ...state,
        uomList: action.payload,
      };
    case DIGI_DOSAGE_OPTIONS:
      return {
        ...state,
        digiDosageOptions: action.payload,
      };
    case DIGI_FINAL_SUCCESS:
      return {
        ...state,
        digitization_success: action.payload,
      };
    case DIGI_PRESCRIPTION_DATA:
      return {
        ...state,
        prescriptionData: action.payload,
      };

    case DIGI_L1TAG:
      return {
        ...state,
        digitizationL1Tag: action.payload,
      };
    case DIGI_PREVIEWIMG_DATA:
      return {
        ...state,
        digiPresImagedata: action.payload,
      };
    case DIGI_IMGVALID_DATA:
      return {
        ...state,
        imgValidationData: action.payload,
      };
    case DIGI_TAB_DATA:
      return {
        ...state,
        tabData: action.payload,
      };
    case DIGI_PRES_STATUS:
      return {
        ...state,
        digitizationStatus: action.payload,
      };
    case DIGI_INPUT_DATA:
      return {
        ...state,
        inputData: action.payload,
      };
    case DIGI_MEDICINE_DATA:
      return {
        ...state,
        medicineData: action.payload,
      };
    case DIGI_SEARCHMEDICINE_DATA:
      return {
        ...state,
        searchmedicineData: action.payload,
      };
    case DIGI_GENERAL_SETTINGS:
      return {
        ...state,
        generalSettings: action.payload,
      };
    case DIGI_SETTINGS_UPDATE:
      return {
        ...state,
        settingsUpdate: action.payload,
      };
    case DIGI_NEWMEDICINE_DATA:
      return {
        ...state,
        searchnewmedicineData: action.payload,
      };
    case DIGI_MEDINSERT_SUCCESS:
      return {
        ...state,
        medicineInsertMsg: action.payload,
      };
    case DIGI_PRESCRIPTION_IMAGES:
      return {
        ...state,
        prescriptionImages: action.payload,
      };
    case DIGI_INPROGRESS_PRESCRIPTION:
      return {
        ...state,
        inprogressPrescription: action.payload,
      };
    case DIGI_REF_MESSAGE_DATA:
      return {
        ...state,
        digitRefMessageID: action.payload,
      };
    case SEGREGATION_SUCCESS:
      return {
        ...state,
        segregationSuccess: action.payload,
      };
    case REVERTSEGREGATION_SUCCESS:
      return {
        ...state,
        revertSegregationSuccess: action.payload,
      };
    case DIGI_TOASTER:
      return {
        ...state,
        toaserMsg: action.payload,
      };
    case DIGI_MED_SUGG:
      return {
        ...state,
        digiAsinSugg: action.payload,
      };
    case DIGI_SUCCESS_MSG:
      return {
        ...state,
        digitiSuccessMsg: action.payload,
      };
    case DIGI_PRESCRIPTIONNUMBER:
      return {
        ...state,
        digiPresNumber: action.payload,
      };
    case DIGI_WARNING:
      return {
        ...state,
        warningMsg: action.payload,
      };
    case DIGI_CURRENT_PRES:
      return {
        ...state,
        currentPrescription: action.payload,
      };
    case DIGITIZATION_DOCTORS_SUGGESTIONS:
      return {
        ...state,
        doctorsuggestions: action.payload,
      };
    case DIGITIZATION_DOCTOR_AUTOFILL:
      return {
        ...state,
        doctorautofill: action.payload,
      };
    case GET_NER_HOSPITALS:
      return {
        ...state,
        nerhospitals: action.payload,
      };
    case GET_DIGITIZATION_NER_INDEX:
      return {
        ...state,
        nerdigiIndex: action.payload,
      };
    case VITALS_SETTINGS_UPDATE:
      return {
        ...state,
        vitalsettingsUpdate: action.payload,
      };
    case DIGI_AUTO_FILL_NOTIFIY:
      return {
        ...state,
        digi_autofill_notify: action.payload,
      };

    default:
      return state;
  }
};
export default digitizationReducer;
